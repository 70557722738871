
$data-table-head: 0.9rem;
$data-table-body: 0.9rem;
$data-table-background: white;
$data-table-header-text-color: #FFAA17;
$data-table-body-text-color: black;
$data-table-body-link-color: #252F4A;
$data-table-body-link-hover-color: #007bff;
$data-table-check-box-color: #f1f1f2;

.p-column-title{
    @apply text-center;
}

.p-datatable .p-datatable-header {
    @apply rounded-t-lg;
    @apply bg-white;
    color: $data-table-header-text-color !important;
    border: none !important;
    padding: 1.5rem 1rem !important;
    font-weight: 600;

}
.p-datatable-tbody{
    font-size: $data-table-body !important;
}

.p-datatable-thead{
    font-size: $data-table-head !important;
}

.p-datatable .p-datatable-thead > tr > th {
    color: $data-table-header-text-color !important;
    border: none !important;
    //border-width: 1px 0 2px 0;
    text-transform: uppercase !important;
    text-align: left !important;
    @apply dark:bg-gray-900 bg-gray-700;
}

.p-datatable .p-datatable-tbody > tr > td {
    @apply  border-b border-dotted dark:border-gray-700 border-gray-400 text-center;
    padding: 1rem 1rem !important;
    color: $data-table-body-text-color !important;
    font-weight:500;
    //@apply dark:bg-accent;
    text-align: right;
}
.p-datatable .p-datatable-tbody > tr > td:nth-child(1),
.p-datatable .p-datatable-tbody > tr > td:nth-child(2),
.p-datatable .p-datatable-tbody > tr > td:nth-child(3){
    text-align: left;
}


.p-datatable .p-datatable-tbody > tr > td > a {
    color: $data-table-body-link-color;
    font-weight:600;
    &:hover{
        color: $data-table-body-link-hover-color !important;
    }
    .dark & {
        @apply text-gray-200;
    }
}

.p-datatable .p-paginator-bottom {
    border: none !important;
    padding: 0px 0px ;

    @apply  rounded-b-lg;
}

.p-datatable .p-datatable-tbody > tr.p-highlight {
    background: $data-table-background !important;
    color: $data-table-body-text-color !important;
}


.p-checkbox .p-checkbox-box{
    border: none !important;
    background: $data-table-check-box-color !important;
}

.p-checkbox .p-checkbox-box.p-highlight {
    background: $data-table-body-link-hover-color !important;
}

// .p-inputwrapper{
//     @apply dark:bg-accent;
// }
.p-dropdown-items-wrapper{
    //@apply dark:bg-accent;
    .p-dropdown-items > li {
        @apply dark:hover:bg-gray-900;
    }
}

.p-highlight{
    @apply bg-transparent dark:hover:bg-gray-900;
}

.p-disabled-row{
    position: relative;
    opacity: 0.4;
    user-select: none;
    pointer-events: none;
    //&::after{
    //    @apply text-center dark:text-gray-300  text-2xl font-bold pt-5;
    //    position: absolute;
    //    content: "🔐 LOCKED";
    //    top:0;
    //    left:0;
    //    //backdrop-filter: blur(1px);
    //    width: 100%;
    //    height: 100%;
    //}

}

.p-sortable-column-icon{
    @apply text-white;
}
