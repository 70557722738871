/* web font size

heading 1 -> 36px -> 2.25rem -> text-4xl
heading 2 -> 30px -> 1.875rem -> text-3xl
heading 3 -> 24px -> 1.5rem -> text-2xl
heading 4 -> 18px -> 1.125rem -> text-lg


paragraph 1 -> 16px -> 1rem -> text-base
paragraph 2 -> 14px -> 0.875rem -> text-sm

small text 1 -> 12px -> 0.75rem -> text-xs
small text 2 -> 10px -> 0.625rem -> text-[0.625rem]
small text 3 -> 8px -> 0.5rem -> text-[0.5rem]

*/

/* Mobile Font Size

heading 1 -> 26px -> 1.625rem -> text-[1.625rem]
heading 2 -> 24px -> 1.5rem -> text-2xl
heading 3 -> 20px -> 1.25rem -> text-xl
heading 4 -> 16px -> 1rem -> text-base


paragraph 1 -> 14px -> 0.875rem -> text-sm
paragraph 2 -> 12px -> 0.75rem -> text-xs

small text 1 -> 10px -> 0.625rem -> text-[0.625rem]
small text 2 -> 8px -> 0.5rem -> text-[0.5rem]
*/

h1 {
  font-size: clamp(1.675rem, 4vw, 2rem);
  line-height: 2.125rem;
  font-weight: bold;
}

h2 {
  font-size: clamp(1.5rem, 3.6vw, 1.625rem);
  line-height: 2rem;
  font-weight: bold;
}

h3 {
  font-size: clamp(1rem, 3.4vw, 1.5rem);
  line-height: 1.5rem;
  font-weight: bold;
}

h4 {
  font-size: clamp(0.75rem, 3.2vw, 1.125rem);
  line-height: 1.5rem;
  font-weight: 600;
}

p {
  font-size: clamp(0.875rem, 3vw, 1rem);
  line-height: 1.25rem;
}
@media (min-width: theme("screens.md")) {
  h1 {
    line-height: 2.5rem;
  }

  h2 {
    line-height: 2.125rem;
  }

  h3 {
    line-height: 1.75rem;
  }
  h4 {
    line-height: 1.75rem;
  }
  p {
    font-size: 1rem;
    line-height: 1.688rem;
  }
}
@media (min-width: theme("screens.2xl")) {
  h1 {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  h2 {
    font-size: 1.625rem;
    line-height: 2.125rem;
  }

  h3 {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }
  h4 {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
  p {
    font-size: 1rem;
    line-height: 1.688rem;
  }
}

.typo-heading1 {
  @apply md:text-4xl text-[1.625rem];
}

.typo-heading2 {
  @apply md:text-3xl text-2xl;
}

.typo-heading4 {
  @apply md:text-2xl text-xl;
}

.typo-heading3 {
  @apply md:text-lg text-base;
}

.typo-paragraph1 {
  @apply md:text-base text-sm;
}

.typo-paragraph2 {
  @apply md:text-sm text-xs;
}

.typo-small1 {
  @apply md:text-xs text-[0.625rem];
}

.typo-small2 {
  @apply md:text-[0.625rem] text-[0.5rem];
}

.typo-small3 {
  @apply text-[0.5rem];
}
